<template>
  <v-container fluid class="pa-0 register">
    <back-to-store :title="$t('menu.register')"></back-to-store>
    <v-container fluid class="pa-16">
      <v-form ref="form">
        <v-row>
          <v-col cols="12" lg="6">
            <p class="text-h5 black-grin text-center text-lg-left">{{$t('profile.firstName')}}</p>
            <v-text-field
              outlined
              v-model="user.first_name"
              required
              :counter="60"
              :rules="validationRules.nameRules"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6">
            <p class="text-h5 black-grin text-center text-lg-left">{{$t('profile.lastName')}}</p>
            <v-text-field
              outlined
              v-model="user.last_name"
              required
              :counter="60"
              :rules="validationRules.nameRules"
            ></v-text-field>
          </v-col>
        </v-row>
        <p class="text-h5 black-grin text-center text-lg-left">{{$t('profile.email')}}</p>
        <v-text-field
          outlined
          v-model="user.email"
          required
          :rules="validationRules.emailRules"
        ></v-text-field>

        <p class="text-h5 black-grin text-center text-lg-left">{{$t('profile.password')}}</p>
        <v-text-field
          outlined
          v-model="user.password"
          type="password"
          :rules="validationRules.passwordRules"
        ></v-text-field>

        <p class="text-h5 black-grin text-center text-lg-left">{{$t('text.verifiedPassword')}}</p>
        <v-text-field
          outlined
          v-model="user.password_confirmation"
          type="password"
          required
          :rules="validationRules.passwordConfirmationRules"
        ></v-text-field>
        <v-row class="py-5">
          <v-col cols="12" lg="6">
            <v-btn class="btn_second" @click="createCustomer()">
              {{$t('text.btnCreateAccount')}}
            </v-btn>
          </v-col>
          <v-col cols="12" lg="6">
            <router-link to="/log-in">
              <v-btn class="transparent px-0  mt-3" elevation="0">
                <p class=" black-grin text-h5 text-right ">{{$t('text.haveAnAccount')}}</p>
              </v-btn>
            </router-link>
          </v-col>
        </v-row>
      </v-form>
       <v-alert dense v-if="alertVisible" text type="error" class="mt-5 alert-success">
        {{ $t("alerts.errorMessage") }}  {{ $t("alerts.tryLater") }} 
      </v-alert>
    </v-container>
  </v-container>
</template>
<script>
import backToStore from "@/components/home/back-to-store.vue";
import { mapActions } from "vuex";
import router from '../../router';

export default {
  components: {
    backToStore
  },
  data() {
    return {
      user: {
        email: null,
        first_name: null,
        last_name: null,
        password: null,
        password_confirmation: null
      },
       alertVisible: false,
    };
  },
  computed: {
    validationRules() {
      return {
        nameRules: [
          (v) => !!v || "Campo requerido",
          (v) => (v && v.length <= 60) || "60 carácteres máximo"
        ],
        emailRules: [
          (v) => !!v || "Campo requerido",
          (v) => /.+@.+\..+/.test(v) || "El correo no es válido"
        ],
        passwordRules: [
          (v) => !!v || "Campo requerido",
          (v) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/.test(v) || "Debe tener mínimo 6 carácteres, incluyendo: 1 mayúscula, 1 minuscula, y 1 número"
        ],
        passwordConfirmationRules: [
          (v) => !!v || "Campo requerido",
          (v) => (v === this.user.password) || "las contraseñas no coinciden",
        ]
      };
    },
  },
  methods: {
    ...mapActions("customer", ["registerUser"]),
     showAlert() {
      this.alertVisible = true;
      var self = this;
      setTimeout(function () {
        self.alertVisible = false;
      }, 4000);
    },
    async createCustomer() {
      if (this.$refs.form.validate()) {
        try {
          await this.registerUser(this.user);
          router.push({ path: '/log-in' });
        } catch (error) {
          this.showAlert();
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.btn_second {
  width: 370px;
}
@media screen and (max-width: 600px) {
  .btn_second {
    width: 100% !important;
  }
}
</style>
